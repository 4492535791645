export const frenchMessages = {
  // Labels //
  Gender: "Genre",
  Title: "Titre",
  Date_of_birth: "Date de naissance",
  "Select_Meal": "Sélectionner le repas",
  Select_Assistance: "Sélectionnez une assistance",
  "Select Assistance": "Sélectionnez une assistance",
  Select_Wheelchair: "Sélectionnez un fauteuil roulant",
  "Select Wheelchair": "Sélectionnez un fauteuil roulant",
  Frequent_Flyer_Program: "Programme de dépliants fréquents",
  Passport_Expiration: "Expiration du passeport",
  Issue_Country: "Pays d'émission",
  Travelbrands_Land_Component_Booking_Number:
    "Numéro de réservation de la composante terrestre de Travelbrands",
  Travelbrands_Cruise_Component_Booking_Number:
    "Numéro de réservation de la composante croisière de Travelbrands",
  Please_enter_Client_Name: "Saisissez le nom du client",
  Morning: "Matin",
  Afternoon: "Après midi ",
  Night: "Nuit",
  Evening: "Soirée",
  Enter_Record_PNR: "Entrez l'enregistrement PNR ",
  Source: "La source ",
  Add_land_portion_fares: "Ajouter des tarifs de portion terrestre",
  Add_cruise_fare: "Ajouter le tarif de croisière ",
  Sabre: "Sabre",
  Amadeus: "Amédée",
  PNR_No: "Pnr",
  Pax_Names: "Noms PAX",
  Pax_Name: "Nom PAX",
  Agent_Name: "Nom de l’agent",
  Invoice_Number: "Numéro de facture",
  By_Reservation_Dates: "Par dates de réservation",
  "Reservation Dates": "Dates de réservation",
  By_Departure_Dates: "Par dates de départ ",
  "Departure Dates": "Dates de départ",
  Client_Name: "Nom du client",
  Client_Email: "Courriel du client",
  City_Pairs: "Origine et destination",
  Airline: "Transporteur",
  Show_Emailed_Quote: "Afficher le devis envoyé par e-mail",
  Form_of_payment: "Forme de payement",
  I_Understand_that_prepaid_seats_may_not_be_Refundable:
    "Je comprends que les sièges prépayés peuvent ne pas être remboursables.",
  Flying_From: "Origine",
  Flying_To: "Destination",
  Departure_date_time: "Date et heure de départ",
  Return_Date: "Date de retour ",
  Passengers: "Passagers",
  Return_date_amp_time: "Date et heure de retour",
  Adults: "Adulte",
  Children: "Enfants",
  Infants: "Bébés",
  Type_of_Fares: "Type de tarifs",
  Air: "Vol",
  AirWallet: "Air Wallet",
  Land: "Portion terrestre",
  Cruise: "Croisière",
  "(Air)": "(Vol)",
  "(Land)": "(Portion terrestre)",
  "(Cruise)": "(Croisière)",
  Type_of_Passengers: "Type de passagers",
  ADT: "ADT",
  ADT_PV: "ADT PV",
  JCB: "JCB",
  PFA: "PFA",
  ITX: "ITX",
  // "Basic economy":"Économie de base", commented
  Basic_Economy_Fares: "Tarifs économiques de base",
  Changes_allowed: "Modifications autorisées",
  Both: "Tous les deux",
  With_Fee: "Avec frais",
  Without_Fees: "Sans frais",
  Alliance: "Alliance",
  SkyTeam: "SkyTeam",
  Star_Alliance: "Alliance star",
  Oneworld: "Un seul monde",
  Search_nearby_airports: "Rechercher les aéroports à proximité",
  At_least_1_free_baggage: "Au moins 1 bagage gratuit",
  Select_Travel_Agency: "Sélectionnez une agence de voyage",
  Select_Travel_Agent: "Sélectionnez une agence de voyage",
  User_Name: "Nom d'utilisateur",
  Password: "Password",
  Include: "Inclure",
  Carriers: "Transporteurs",
  Fares_with_free_bag: "Tarifs avec bagage(s) gratuit(s)",
  Fares_with_free_seat_selection: "Tarifs avec sélection de siège libre",
  Fare_matrix_showing:
  "Grille tarifaire affichant les tarifs les plus bas pour un départ  ",
  and_Return_on: "  et un retour",
  and_on: " et sur  ",
  "I confirm that the names":
    "Je confirme que les noms et les détails affichés ci-dessus sont complets et corrects au meilleur de ma connaissance. En réservant un voyage par le biais de ce logiciel, je confirme que j'ai lu et accepté les modalités et conditions qui y sont décrites.",
  Signature: "Signature",
  "Voided":"Annulé",
  "I want to continue and book":
    "Je veux continuer et réserver un siège pour le bébé.",
  "I agree to contact the airline.":
    "J'accepte de contacter la compagnie aérienne. Continuez et réservez.",
  "Apply for all flights": "Appliquer pour tous les vols",
  "Spacial Type": "Type spatial",
  "Spacial Wheelchair": "Fauteuil roulant spacial",
  "Spacial Assitance": "Assistance spaciale",
  "Frequent Flyer Programme": "Programme de dépliants fréquents",
  "Frequent Traveler Number": "Numéro de voyageur fréquent",
  "Redress Number": "Numéro de recours",
  "Additional Markup": "Marge de profit supplémentaire",
  "Known Traveler Number": "Numéro de voyageur connu",
  "Departure City": "Ville de départ",
  "Arrival City": "Ville d'arrivée",
  position: "position",
  "Credit Card": "Carte de crédit",
  Cheque: "Chèque",
  "Card Number": "Numéro de carte",
  "Cardholder Name": "Nom du titulaire",
  "Expiry Date": "Date d'expiration",
  CVV: "CVV",
  "Cheque Number": "Numéro du chèque",
  "Apply same payment method for all the travelers":
    "Appliquer le même mode de paiement pour tous les passagers",
  "Net Fares": "Tarifs nets",
  "Land Net Fares": "Tarifs nets avec portion terrestre",
  "Cruise Net Fares": "Tarifs nets avec croisière",
  "Net Fare": "Tarif net",
  "Pax Type": "Type PAX",
  Commissionable: "Avec commission",
  "Non Commissionable": "Sans commission",
  "Net Base Fare": "Tarif net de base",
  "Calculator searches for all fare contract types AIR-LAND-CRUISE":
    "Le calculateur recherche tous les types de contrats tarifaires VOL-PORTION TERRESTRE-CROISIÈRE",
  "Reprice the itinerary at the lowest available fares":
    "Recalculer l'itinéraire aux tarifs les plus bas disponibles.",
  "Reprice the itinerary in current classes of service using the same fare option when known":
    "Recalculer l'itinéraire dans les classes de service actuelles en utilisant la même option tarifaire lorsqu'elle est connue.",
  "Waiting For Update":"En attente de mise à jour...",
  // links //
  TravelGenie: "Voyagerie",
  Sunquest: "Sunquest",
  Flights: "Vols",
  Hotels: "Hôtels",
  Cars: "Voitures",
  Cruises: "Croisières",
  Add_Ons: "Add-Ons",
  Disney: "Disney",
  Featured_Destinations: "Destinations en vedette ",
  Specialty_Travel_Lujure: "Voyage spécialisé / Lujure",
  SAVED_ITINENARY: "ITINÉRAIRE SAUVEGARDÉ",
  MY_BOOKINGS: "MES RÉSERVATIONS",
  GROUPS: "GROUPES",
  search_Results: "Résultats de la recherche",
  My_files: "Mes dossiers",
  My_Saved_Quotes: "Mes itinéraires sauvegardés",
  Calculator: "Calculateur",
  Default_View: "Par transporteur",
  By_Fare_type: "Par type de tarif",
  "Back to results": "Retour aux résultats",
  "Back to calculator": "Retour à la calculatrice",
  "Edit Details": "Modifier les détails",
  "Select Seats": "Sélectionner les sièges",
  "Frequent Traveler Numbers, Passport Info":
    "Nombres de voyageurs fréquents, informations sur les passeports",
  Save: "Sauvegarder",
  Edit: "Éditer",
  Edits:"Modifier",
  "Want to avoid fees ?": "Vous voulez éviter les frais?",
  "Frequent Traveler, Nexus":
    "Voyageur fréquent, lien, réparation, informations sur les passeports",
  "There will be two separate":
    "Il y aura deux frais distincts sur la déclaration de carte de crédit des passagers",
  "Frequent Traveler, Redress, Passport Info":
    "Voyageur fréquent, numéro de recours, informations sur les passeports",
    "Frequent Traveler, Passport Info":
    "Informations sur les passeports",
    "Frequent Traveler":
    "Voyageur fréquent",
  "Default Markup":"Marge de profit par défaut",
  // Buttons //
  Search: "Recherche",
  Search_3_days: "Recherche +/- 3 jours",
  Clear_Form: "Effacer",
  RoundTrip: "Aller-retour",
  OneWay: "Aller simple",
  OpenJaw: "Open Jaw",
  MultiCity: "Multi-destinations",
  Modify_Search: "Modifier la recherche",
  Apply_Filter: "Appliquer le filtre",
  Yes: "Oui",
  No: "Non",
  "Apply Payment": "Appliquer le paiement",
  Reset: "Réinitialiser",
  Send: "Envoyer",
  Cancel: "Annuler",
  "Confirm Seats": "Confirmer les sièges",
  "Reset All": "Effacer tout",
  "Complete Selection": "Compléter la sélection",
  "Next Flight": "Prochain vol",
  "Continue Adding Seats": "Continuez à ajouter des sièges",
  "Remove Selected Seats": "Retirer les sièges sélectionnés",
  View: "Afficher",
  "Display List": "Liste d'affichage",
  "Free Baggage": "Bagages gratuits",
  "Send Quote": "Sauvegarder l’itinéraire",
  Continue: "Continuer",
  book: "RÉSERVER",
  "Seat Map": "Plan de la cabine",
  "View Rules": "Afficher les règles",
  "Hold Without Payment": "Réserver sans paiement",
  "Book With Payment": "Réserver avec paiement",
  "Cancel Booking": "Annuler la réservation",
  OK: "D'ACCORD",
  Close: "Fermer",
  "Clear All Filters": "Effacer tous les filtres",
  Filter: "Filtre",
  "Log out": "Se déconnecter",
  "Multiple Fare Options":"Options Tarifaires Multiples",
  // DropDown Lists //

  "Time": "Heure",
  Cabin: "Cabine",
  Economy: "Économie",
  'Mixed Cabin':"Combinaison de cabines",
  PremiumEconomy: "Économie supérieure",
  "Premium Economy": "Économie supérieure",
  Business: "Affaires",
  Mobile: "Cellulaire",
  Home: "Résidence",
  "Select Meal" :"Sélectionnez le repas",
  "Meet and Assist ": "Accueil et assistance",
  Blind: "Non-voyant",
  Deaf: "Malentendant",
  "Wheelchair/Passenger can walk up stairs":
    "Fauteuil roulant/passager peut monter les escaliers à pied.",
  "Wheelchair/Passenger can walk to seat":
    "Fauteuil roulant/Passager peut se rendre à son siège",
  "Wheelchair/Passenger must be carried":
    "Fauteuil roulant/passager doit être porté",
  "Wheelchair on board": "Fauteuil roulant à bord",
  First: "Première classe",
  "Select Carriers": "Sélectionnez les transporteurs",
  "Sky Team": "SkyTeam",
  "Star Alliance": "Star Alliance",
  "One World": "Oneworld",
  "All Carriers": "Tous les transporteurs",
  "Specific Airlines": "Transporteurs spécifiques",
  Maximum_connections: "Correspondances maximales",
  "Direct flights": "Vols directs",
  One: "Une",
  Two: "Deux",
  Three: "Trois",
  Connections: "Correspondances",
  "connection airports": "Aéroports de correspondance",
  Class: "Classe",
  " Add another flight":"Ajouter un autre vol",
  

  // Placeholders, Div, Spans, Button & filters //
  Select_Agent: "Sélectionnez l’agent",
  Select_Agency: "Sélectionnez l’agence",
  Enter_any_airline: "Entrez n'importe quelle compagnie aérienne",
  Inner_Content: "Contenu intérieur",
  Connection_duration: "Durée de la correspondance",
  Trip_Duration: "Durée du voyage",
  DEPARTURE_TIMES: "Heures de départ",
  ARRIVAL_TIMES: "Heures d’arrivée",
  AIRLINES: "Transporteurs",
  Alliances: "Alliances",
  Fare_Type: "Type de tarif",
  Price_Range: "Échelle des prix",
  Price_allowing_refunds: "Prix ​​permettant des remboursements",
  All_Prices: "Tous les prix",
  Fares_with_free_bag_allowance: "Tarifs avec une allocation de sac gratuite",
  "Please enter Required Fields.": "Veuillez saisir les champs requis",
  "Please enter First Name.": "Veuillez entrer le prénom.",
  "Please enter Last Name.": "Veuillez entrer le nom de famille.",
  "Please enter Email.": "Veuillez saisir le courrier électronique.",
  "Please enter Phone Number.": "Entrez le numéro de téléphone valide.",
  "Please enter 2 or more characters": "Veuillez saisir 2 caractères ou plus",
  "Please enter 1 or more characters":
    "Veuillez saisir 1 ou plusieurs caractères",
  "Your Service Fee Per Passenger":
    "Les frais de service de votre agence par passager",
  "Choose Fare Options": "Choisissez une option tarifaire",
  "Passengers Details": "Détails des passagers",
  "Includes merchant fees": "Comprend les frais de marchand",
  "Enter an amount lower than": "Entrez un montant inférieur à",
  "for Adult": "pour adulte",
  "for Child": "pour l'enfant",
  "for Infant": "pour le bébé",
  "Pay using Cheque": "Payer en utilisant le chèque",
  "If Paying By Cheque": "Si payer par chèque",
  "If Paying By Card": "Si payer par carte",
  "Back to Confirmation":"Retour à Confirmation",
  "Go to Void cancellation Verbiage":"Merci. L'itinéraire du dossier XXXXXX et tous les documents électroniques sont annulés.",
  "Go to Void cancellation Verbiage1":"Si payé par carte ce crédit, l'annulation de l'autorisation prise sur la carte peut prendre quelques jours.",
  "Regular Refund Verbiage" : "Merci. L'itinéraire du dossier XXXXXX a été cancellé et le dossier sera révisé par notre service des remboursements.",
  "Regular Refund Verbiage1" : "Tous documents émis et éligibles à un remboursement seront traités d'ici les sept prochains jours.",
  "Regular Refund Verbiage2" : "Si payé par carte ce crédit, le remboursement peut prendre jusqu'au prochain relevé de carte de crédit pour être complété.",
  "On Option Cancellation Verbiage":"Merci. L'itinéraire du dossier XXXXXX a été annulé.",
  "Go to 24hrs Refund Verbiage":"Merci.L'itinéraire du dossier XXXXXX a été cancellé et tous les billets électroniques seront remboursés intégralement.",
  "Go to 24hrs Refund Verbiage1":"Si payé par carte ce crédit, le remboursement peut prendre jusqu'au prochain relevé de carte de crédit pour être complété.",
  "Go to 24hrs Refund Verbiage2":"Si votre dossier contient des Bons d'Échange Électroniques,tels que 'emds' pour sièges prépayés,le remboursement est en demande et sujet à l'approbation du transporteur aérien.",
  "Paid Not Ticketed Cancellation Verbiage":"Merci. L'itinéraire du dossier XXXXXX a été annulé.",
  "Paid Not Ticketed Cancellation Verbiage1":"Si payé par carte ce crédit, l'annulation de l'autorisation prise sur la carte de crédit peut prendre quelques jours.",
  AirLine: "transporteur",
  Intair: "Intair",
  "Amount Breakup:": "Éclatement du montant:",
  To: "Au",
  Departure: "Départ",
  Return: "Aller-retour",
  "View 2 More City Pairs": "Voir 2 autres paires de villes",
  "Adult": "Adulte",
  Child: "Enfant",
  Infant: "Bébé",
  From: "Du",
  "InfantInLap":"Bébé",
  "Please enter email":"Veuillez saisir un e-mail",
  "Please enter Valid email":"Veuillez entrer une adresse e-mail valide",
  "Are you sure?": "Êtes-vous certains(es)?",
  "Do you want to Cancel ?": "Voulez-vous annuler ?",
  "Your file shows that electronic documents":"Votre dossier montre que les documents electroniques ont été émis.",
  "The itinerary will be cancelled":"L'itinéraire sera annulé est le dossier envoyé à notre département des remboursements.",
  "Any documents issued and eligible":"Tout document émis et éligible à un remboursement sera traité à l'intérieur des 7 jours.",
  "If paid by credit card, the refunded amount":"Si payé par carte ce crédit, le remboursement peut prendre jusqu'au prochain relevé de carte de crédit pour être complété.",
  "Are you sure you want to cancel" : "Êtes-vous certain de vouloir  annuler ?",
  "Your file is currently":
    "Votre dossier est présentement en option; êtes-vous certain de vouloir annuler?",
  "Your file shows that a payment":
    "Votre dossier montre qu'un paiement a été fait mais les documents ne sont pas encore émis; êtes-vous certain de vouloir annuler?",
  "TOTAL FOR PREPAID SEATS": "Total pour les sièges prépayés",
  TOTAL: "TOTAL",
  "Total Markup": "Marquage total",
  "Equivalent Published Fare": "Tarif publié équivalent",
  "There is no equivalent published":
    "Nous sommes désolés, il n'y a pas de tarif publié équivalent pour cet itinéraire.",
  "Airline taxes & fees": "Taxes et frais du transporteur",
  Per: "Par",
  "Base Fare": "Tarif de base",
  "Per Infant": "Par bébé",
  "Per child": "Par enfant",
  "Per Adult": "PAR ADULTE",
  "All Airlines": "Toutes les compagnies aériennes",
  "Total Trip Duration · Under 20hr": "Durée totale du voyage · Sous 20 heures",
  "Layover Duration · Any": "Durée de l'escroquerie · Tout",
  "Hide Nearby Airports": "Cacher les aéroports voisins",
  "23 fares": "23 tarifs",
  "28 fares": "28 tarifs",
  "Hide Changes of Airport": "Masquer les changements d'aéroport",
  "fares from": "tarifs de",
  "Direct only": "Direct seulement",
  Upto: "Jusqu'à",
  cnx:" Corresp",
  "Sort & FILTER": "Trier et filtrer",
  "All Alliances": "Toutes les alliances",
  "Low Cost Carriers": "Transporteurs à faible coût",
  "Multi-ticket pricing": "Prix ​​multi-billets",
  "Published fares": "Tarifs publiés",
  "Bulk fares": "Tarifs en vrac",
  "Cruise fares": "Tarifs de croisière",
  "Land Fares": "Tarifs terrestres",
  "No Data": "Pas de données",
  "Change Date":"Modifier la date",
  "Please Enter Date": "Veuillez entrer la date",
  Duration: "Durée",
  "Change Fees": "Changement de frais",
  "Apply Markup": "Appliquer la marge de profit",
  "Current Commission": "Commission actuelle",
  "Send Email": "Envoyer un courriel",
  Delete: "Supprimer",
  "Ticket By: INSTANT PURCHASE": "Ticket par: achat instantané",
  "Ticket By:": "Ticket par:",
  "View Details": "Afficher les détails",
  "No Result": "Pas de résultat",
  "Do you want to Delete?": "Voulez-vous supprimer l’itinéraire enregistré?",
  QuoteList: "Quetelist",
  Details: "Détails",
  "Quote For:": "Citation pour:",
  "Markup Applied": "Marge de profit appliquée",
  Taxes: "Taxes",
  Markup: "Marge de profit",
  "Total payable Amount": "Montant total payable",
  "Enter new Card Details": "Entrez les nouvelles détails de la carte",
  "Same card": "Même carte",
  "Same Card ending in": "Même carte se terminant",
  "Added in the fare:": "Ajouté dans le tarif:",
  all: "TOUS",
  ALL: "TOUS",
  "For seats to be confirmed":
    "Pour que les sièges soient confirmés, il est obligatoire de sélectionner des sièges pour",
  "the travelers.": "les voyageurs.",
  "Select title.": "Sélectionnez le titre.",
  "Please Select valid DOB.": "Sélectionnez la date de naissance.",
  "No result found": "Aucun résulat trouvé.",
  "Select gender.": "Entrez le genre",
  "First Name": "Prénom",
  "Middle Name": "Deuxième nom",
  "Last Name": "Nom de famille",
  "Phone Number": "Numéro de téléphone",
  "Email Address": "Adresse courriel",
  "Online refund procedure and Form":
    "Procédure et formulaire de remboursement en ligne",
  "Record Locator": "Numéro du dossier",
  "Total Duration:": "Durée totale:",
  Outbound: "Aller",
  "Flight Details": "Détails de l’itinéraire",
  Inbound: "Retour",
  Layover: "Halte",
  "Price paid for prepaid seats":
    "Le prix payé pour les sièges prépayés a été mis à jour par les transporteurs.",
  "Ticket No :": "N ° de billet:",
  "EmdTicket No :": "EMDTICKET NO:",
  "Passport Number -": "Numéro de passeport -",
  "Passport Expiration -": "Expiration du passeport -",
  "Issued Country -": "Pays émis -",
  "Travel Agent Information": "Informations sur l’agent de voyage",
  "TRAVELBRANDS - BUSINESS": "TravelBrands - Business",
  "Booking Status": "Statut de la réservation",
  "Ticketing Status:": "Statut de l’émission:",
  "Not ticketed": "Pas éculé",
  Failed: "Manqué",
  TICKETED: "ÉMIS",
  Ticketed: "Émis",
  CANCELLED: "ANNULÉ",
  Cancelled: "Annulé",
  "Email Booking": "Envoyer réservation & billets électroniques par courriel",
  "ON HOLD": "EN OPTION",
  "On Hold": "En Option",
  "Travelbrands Service":
    "Frais de service de Voyages TravelBrands par passager",
  "Taxes Included": "Taxes incluses",
  "Fare Option": "Option tarifaire :",
  "All fees incurred by providing":
    "Tous les frais encourus suite à la transmission d’un numéro de réservation fictif seront entièrement facturés à l’agence de voyage, y compris les notes de débit, les réclamations ou les procédures judiciaires. La compagnie aérienne peut demander une preuve avant de délivrer une carte d’embarquement.",
  "on Airline": "en compagnie aérienne",
  "Credit card payment on Intair": "Paiement de carte de crédit sur Intair",
  "By Cheque": "Par chèque",
  "Total Amount Paid": "Montant total payé",
  "Service fee": "Frais de service",
  "Ticketing fee": "Frais d'émission",
  "Total Taxes Fees:": "Total des taxes et frais:",
  "Review your booking": "Passez en revue votre réservation",
  "Flight Selected": "Vol sélectionné",
  "Booking Details": "Détails de la réservation",
  Payments: "Paiement",
  "Booking is confirmed!": "La réservation est confirmée!",
  "Do you want to save records?":
    "Voulez-vous enregistrer des enregistrements?",
  "Mandatory land component":
    "La composante terrestre obligatoire vendue par TravelBrands est requise. L'agent de voyage accepte les sanctions imposées si elle n'est pas suivies.",
  "Mandatory cruise sold":
    "Une croisière obligatoire vendue par TravelBrands est nécessaire. L'agent de voyage accepte les sanctions imposées si elle n'est pas suivies.",
  "Selected Fare Option:": "Option tarifaire sélectionnée:",
  "Special Request to all Flight" : "Les demandes spéciales seront appliquées sur tous les vols. Vous voulez ajouter des demandes spéciales différentes pour chaque vols?",
  "Special Request to each Flight" : "Vous voulez appliquer les demandes spéciales sur tous vos vols?",
  "Click here":"Cliquez ici",
  "Brand Code :": "Code de marque:",
  "Basic Seat": "Siège standard",
  "Standard Seat": "Siège standard",
  "Change after departure": "Changer après le départ",
  "Change before departure": "Changer avant le départ",
  "Refund after departure": "Remboursement après le départ",
  "Refund before departure": "Remboursement avant le départ",
  Priority: "Priorité",
  "Priority baggage": "Bagages prioritaires",
  "Priority check in": "Enregistrement prioritaire",
  "Priority security": "Sécurité prioritaire",
  "Priority boarding": "Embarquement prioritaire",
  "View Seat map": "Afficher le plan de la cabine",
  "Checked bags included": "Les bagages enregistrés inclus",
  Bags: "Bagages",
  "Cabin bag": "Sac de cabine",
  Preferred: "Préféré",
  "Publish Non Commissionable Fare": "Publier un tarif non commandable",
  "Pricing per adult": "Prix ​par adulte",
  Fees: "Frais",
  "Changes and Refund": "Changements et remboursement",
  "Changeable ticket": "Billet modifiable",
  "Refund after checkin closure": "Remboursement après la fermeture du chèque",
  "Refund before checkin closure":
    "Rembourser avant la fermeture de la vérification",
  "Layover in": " Arrêt à ",
  "Loyalty Points": "Points de fidélité",
  "Cards Accepted": "Cartes acceptées",
  "Travelbrands has found a better fare from":
    "TravelBrands a trouvé un meilleur prix de",
  "Airline has increased the fare from":
    "La compagnie aérienne a augmenté le prix de",
  "Payment failed": "Paiement échoué. Veuillez utiliser une autre carte.",
  "Payment failed. Please":
    "Paiement échoué. Veuillez utiliser une autre carte ou choisir vérifier.",
  "Additional Bags": "Bagages supplémentaires",
  "up to": "Jusqu'à",
  "INSTANT PURCHASE": "Achat instantané",
  "Total Flight Time": "Temps de vol total",
  "Total Trip Duration": "Durée totale du voyage",
  "Seats": " SIÈGES STD",
  "Fare Basis": "Base tarifaire",
  Facilities: "Installations",
  "Traveller details": "Détails des voyageurs",
  "matches our fraudulent passenger names database.":
    "correspond à notre base de données de noms frauduleux. Si vous êtes certains que ce passager n'est pas un fraudeur, contactez-nous pour procéder à la réservation.",
  "Land Confirmation No": "Confirmation des terres Non",
  "Passenger Details": "Détails du passager",
  "Payment Summary": "Sommaire du paiement",
  "Price Summary": "Sommaire du prix",
  "Add Traveler Info": "Ajouter les informations du passager",
  "Add your markup amount": "Ajoutez votre marge de profit",
  "Taxes and Fees": "Taxes et frais",
  "Operated by:": "Opéré par : ",
  "Economy Class": "Classe économie",
  Type: "Type",
  "Check-in": "Enregistrement",
  Piece: "  Pièce",
  Penalties: "Pénalités",
  Rules: "Règles",
  "Please check Date": "Veuillez vérifier la date",
  Seat: "Siège",
  "Change in case of no show": "Changement en cas de non-spectacle",
  "- Primary Contact": "- Contact principal",
  "Primary Contact Info": "Informations du contact principal",
  "Contact Details": "Détails du contact",
  "Your Contact information":
    "Vos coordonnées seront partagées avec le transporteur",
  "Special Request": "Demande spéciale",
  "Seat Selection Details": "Détails de la sélection des sièges",
  "Seat Selection": "Sélection des sièges",
  "No seats are selected yet": "Aucun siège n'est encore sélectionné",
  "Waiting For Seat Update...": "En attente de mise à jour du siège ...",
  "Prepaid Seats Were Not Booked":
    "Les sièges prépayés n'étaient pas réservés car les billets n'ont pas été émis.",
  "Payment_confirmation_msg":"J'ai lu et compris la procédure de remboursement en ligne de Voyages TravelBrands",
  "Calculator needs your 4 digits Intair agency code" : "Le calculateur a besoin de votre numéro d’agence à 4 chiffres avec Intair pour effectuer une recherche de prix  pour votre dossier.",
  "Add this remark in your pnr exactly as shown":"Veuillez ajouter cette remarque telle quelle:",
  "Intair code" : "5**PNR OWNER-CXXXX** .",
  "XXXX is your Intair 4-digit agency code":"XXXX correspond à votre numéro d’agence à 4 chiffres avec Intair.  Veuillez ajouter des 0 si votre numéro contient moins de 4 chiffres. Ex. C0256 ou C0066.",
  "Do not forget to save the remark":"N’oubliez pas d’enregistrer la remarque (ER) dans votre dossier avant de le soumettre à nouveau.",
  or: "ou",
  Male: "Masculin",
  Female: "Féminin",
  "Any request to process changes":
    "Toute demande de changement ou de correction du nom de famille, du second nom, du prénom et du titre du passager est désormais sujette à nos  ",
  "fees of 50.00 $/":
    `frais de service de 50,00 replacewithDollarValue/passager plus les taxes applicables.`,
  "You are liable for providing":
    "Vous êtes responsable de fournir les noms et prénoms tels qu'ils sont inscrits sur le passeport.",
  Payment: "Paiement",
  "By Card Ending In": "Par carte se terminant dans",
  "Please Note that the taxes were increased":
    "Veuillez noter que les taxes ont été augmentées",
  "Please Note that the taxes were decreased":
    "Veuillez noter que les taxes ont été diminuées",  
  "Edit Markup": "Modifier la marge de profit",
  "Existing seat": "Siège existant",
  "Class Seat": "Siège de classe",
  "New seat": "Nouveau siège",
  "Preferred Free": "Préférentiel gratuit",
  "Preferred Paid": "Préférentiel payant",
  Free: "Libre",
  Paid: "Payè",
  "PaidSeat":"Payant",
  PAID: "PAYÉ",
  "Not a Seat": "Pas un siège",
  "Not Available": "Indisponible",
  Closet: "Placard",
  Galley: "Cuisine",
  Lavatory: "Toilettes",
  "Contact Carrier": "Contactez le transporteur",
  Exit: "Sortie",
  "This seat map is for viewing":
    "Ce plan de cabine est à titre informatif uniquement.",
  "Prepaid seats must be booked":
    "Vous selectionnerez votre siège ultérieurement.",
  Limitation: "Limitation",
  "Exit Door": "Porte de sortie",
  "Sharing the primary contact":
    "Le partage des coordonnées du contact principal",
  "Select Seat": "Choisissez votre siège",
  "Paid Contact Carrier": "Opérateur de contact payant",
  "i.e. mobile phone number":
    "c’est-à-dire le numéro de téléphone mobile et/ou l’adresse courriel,",
  "with the airlines is mandatory":
    "avec les transporteurs est obligatoire. Les transporteurs utiliseront ces informations pour des avis opérationnels tels que l’annulation de vols ou des changements d’itinéraire. Les transporteurs ne permettent pas de fournir les coordonnées de l’agence de voyage au lieu de celles du passager.",
  "The maximum number of passengers":
    "Le nombre maximum de passagers que vous pouvez réserver est 9. Veuillez réessayer.",
  "Tkt By": "Émission",
  "Sort By": "Trier par",
  "Search By": "Rechercher par",
  "Reprice PNR": "Recalculer le PNR",
  "Show list for": "Afficher la liste de",
  customDate: "Date personnalisée",
  "Custom Date": "Date personnalisée",
  TODAY: "Aujourd’hui",
  YESTERDAY: "Hier",
  Today: "Aujourd’hui",
  Yesterday: "Hier",
  "This Week": "Cette semaine",
  "This Month": "Ce mois-ci",
  "Last Week": "Semaine passée",
  Archived: "Archivé",
  Remove: "Retirer",
  "Add Flight": "Ajouter le vol",
  "Close Advanced Search": "Fermer la recherche avancée",
  "Prepaid Seats Payment": "Paiement des sièges prépayés",
  "Travel within 72 hours":
    "Le voyage dans les 72 heures peut être une fraude. Voulez-vous procéder à la réservation?",
    "Ticketing Status Verbiage" : "Si payé par carte ce crédit, l'annulation de l'autorisation prise sur la carte peut prendre quelques jours.",  
  "Travel within 72 hours isn’t":
    "Le voyage dans les 72 heures n'est pas autorisé.",
  "More City Pairs": " segments supplémentaires",
  "Your File Shows That Electronic Documents Are Issued":
    "Votre dossier montre que des documents électroniques sont émis. L’itinéraire et tous les documents électroniques seront annulés.",
  Reservation: "Réservation",
  Status: "Statut",
  Destination: "Destination",
  "Invalid Card Number":"Numéro de carte invalide",
  "PNR Number": "Numéro PNR",
  "Important Note:": "Note importante:",
  "If Electronic Miscellaneous Documents":
    "Si des documents électroniques divers, tels que les EMDs prépayés, sont présents dans votre dossier, le remboursement est sur demande et sous réserve de l'approbation du transporteur.",
  "We Are Sorry But Online Cancellation For This File":
  "Nous sommes désolés mais l'annulation en ligne pour dossier ",
  "Is Not Allowed. Please Contact Us.":
  "n'est pas permise. Veuillez nous contacter.",
  "Upon completing and submitting":
    "Après avoir rempli et envoyé ce formulaire, votre dossier sera révisé par notre service de remboursement. Les conditions de remboursement seront vérifiées pour chaque billet et bon d'échange électronique (EMDs).",
  "electronic miscellaneous documents(EMDs).":
    "Documents variés électroniques (EMD).",
  "If refundable": "Si remboursable",
  "The carrier's cancellation fees":
    "Les frais d'annulation du transporteur seront calculés et déduits conformément aux règles tarifaires applicables. * Les frais d'annulation les plus restrictifs peuvent s'appliquer.",
  "Fuel and miscellaneous surcharges":
    "Les surcharges d'essence ou autres ayant le code YQ/YR sont sujettes à la politique de remboursement de chaque transporteur aérien et peuvent ou ne pas être remboursées que le tarif soit remboursable ou pas.",
  "Travelbrands service fees will be applicable":
    "Les frais de service de Voyages TravelBrands seront applicables seulement s'il y a des frais d'annulation du transporteur aérien dans les règles tarifaires.",
  "Travelbrands administrative":
    "Les frais administratifs de TravelBrands pour le paiement des billets d'avion par carte de crédit ne sont pas remboursables.",
  "Loyalty points awarded":
    "Les points fidélité attribués seront ajustés en conséquence",
  "Your commission in full":
    "Votre commission sera réclamée en totalité.",
   "Payment for prepaid seats was not attempted." : "Le paiement pour les sièges payants n'a pas été effectué.", 
  "You can charge your own agency's":
    "Vous pouvez charger les frais de service de votre agence et inclure votre commission réclamée dans le total.",
    "Refund details and comment":
    "Les détails et commentaires du remboursement seront indiqués sur la facture finale.",
  "Refund details and comments":
    "Les détails de remboursement et les commentaires seront indiqués sur la facture finale.",
  "If 100% non-refundable": "Si 100% non remboursable",
  "Travelbrands service's fees do not apply.":
    "Les frais du service de Voyages TravelBrands ne s'appliquent pas.",
  "If the value of the unused":
    "Si la valeur du billet inutilisé peut être utilisé comme crédit pour l'achat d'un nouveau billet, ce sera indiqué sur la facture finale.",
  "Some airport taxes may be":
    "Certaines taxes d'aéroport peuvent être remboursées selon la politique de remboursement de chaque transporteur aérien. Nous allons vérifier et rembourser conformément. Dans un tel cas, les frais de service de Voyages TravelBrands s’appliqueront.",
  "Prepaid Seats": "Sièges prépayés",
  "No Data To Show": "Aucune donnée à montrer",
  Traveler: "Voyageur",
  "Emergency Exit Row": "Ligne de sortie d'urgence",
  "Free seats": "Sièges gratuits",
  "Paid Seats": "Sièges payants",
  "Existing Fare": "Tarif existant",
  "Maximum Markup Allowed": "Marge de profit maximum permise",
  "New option date exceeds maximum permitted date":"La nouvelle  date d’option excède la date maximum permise.",
  MR: "M",
  MS: "MME",
  MRS: "MME",
  DR: "Dr",
  MSTR: "MSTR",
  MISS: "MISS",
  "Add Your Travelbrands cruise":
    "Ajoutez votre numéro de réservation de composants de croisière TravelBrands",
  "Add Your Travelbrands land component":
    "Ajoutez votre numéro de réservation de composant terrestre Travelbrands",
  For: "Pour",
  "Fare is": "Le tarif est",
  by: "par",
  "Cancel Details": "Annuler les détails",
  "View Branded Fares": "Afficher les options tarifaires",
  Itinerary: "Itinéraire",
  "Change Flight": "Changer la vol",
  "There will be two seprate charges on the passangers credit card statement":
    "Il y aura deux frais de séparation sur la déclaration de carte de crédit Passangers",
    "cached_expired":"Il semble que vous ayez gardé la page ouverte pendant un certain temps. Effectuer une nouvelle recherche maintenant pour vous montrer les derniers résultats.",
  "Charged by": "Chargé par",
  "Showing lowest fare for +/- 3 days":
    "Affichage des tarifs les plus bas excluant les tarifs PUB(WEB) pour +/- 3 jours",
  "Failed to Update:": "Échec de mise à jour:",
  "New Fares": "Nouveaux tarifs",
  "Select type": "Sélectionnez le type.",
  Required: "Requis",
  "*Invalid Expiry Date":"*Date d’expiration invalide",
  "Frequent Traveler Airline Code/Name":
    "Code / nom de la compagnie aérienne fréquente des voyageurs",
  "Frequent Traveler Airline...": "Transporteur pour voyageur ...",
  "Your File Shows That Electronic Documents Have Been Issued.":
    "Votre fichier montre que des documents électroniques ont été émis. L'itinéraire sera annulé et tous les billets électroniques remboursés en totalité. S'il est payé par carte de crédit, le remboursement ne peut être traité que le prochain relevé de carte de crédit.",
  "We are sorry,a technical issue prevents":
    "Nous sommes désolés, un problème technique nous empêche de traiter votre paiement en ligne. Veuillez nous contacter.",
  "   Payment failed": "Paiement échoué.",
  "Enter Country": "Entrer dans le pays",
  "Special character not allowed": "Personnage spécial non autorisé",
  "More Trips": " segments supplémentaires.",
  Direct: "Direct",
  AnyTime: "À toute heure",
  "Cancellation Fees": "Frais d'annulation",
  "Provider Id": "ID du fournisseur:",
  Baggage: "Bagages",
  Terminal: "Terminal ",
  "Fare results is a combination of multiple":
    "Les résultats sont une combinaison de multiples bases tarifaires. Les pénalités sont soumises à de multiples frais et conditions. Référez-vous aux règles de chaque base tarifaire.",
  "over wing seats": "sur des sièges d'aile",
  Amount: "Montant",
  "seat map is for viewing only.":
    "La carte du siège est pour la visualisation uniquement. Vous sélectionnerez votre siège plus tard.",
  "Lower Deck": "Pont inférieur",
  "Prepaid seats can be selected":
    "Les sièges prépayés peuvent être sélectionnés après une réservation.",
  "Advanced Search": "Recherche Avancée",
  fares: " tarifs",
  "Enter Airline": "Saisissez le nom du transporteur",
  "Enter Name": "Entrez le nom",
  "Change & Cancellation": "Changement et annulation",
  "Fee for additional bag": "Frais pour bagages supplémentaires ",
  "Enter valid First Name": "Entrez le prénom valide",
  "Enter valid Last Name": "Entrez le nom de famille valide",
  "Enter Gender": "Entrez le genre.",
  "Enter Mobile Number": "Entrez le numéro de mobile",
  "Enter Phone Number" : "Entrez le numéro de téléphone",
  "Enter Valid Email Address": "Entrez l'adresse e-mail valide",
  specialChrError: "SpecialChrerror",
  "Submit Payment": "Soumettre le paiement",
  "Select Language": "Choisir la langue",
  "Save Details": "Enregistrer les détails",
  "Fare has Decreased": "Le prix a diminué",
  "Fare has Increased": "Le tarif a augmenté",
  "Email Language": "Langue de correspondance",
  "Quote List": "Liste d’itinéraires sauvegardés",
  None: "Aucun",
  Total: "Total",
  seats: "des places",
  "Add/Edit Markup": "Ajouter / Modifier la marge de profit",
  "TOTAL SELLING AMOUNT": "Montant total",
  "Payable Amount": "Montant à payer",
  "If Paying By": "Si payé par",
  "Amount Breakdown:": "Transactions carte de crédit:",
  "amount higher than the markup amount":"Un montant plus haut que la marge de profit indiquée ci-bas entraînera 2 transactions séparées sur la carte de crédit.",
  "Adding a markup amount":" Ajouter une marge de profit entraînera 2 transactions séparées sur la carte de crédit.",
  "Waiting For Markup Update...": "En attente de la mise à jour de la marge de profit. ...",
  "Please use below option to edit Intair Fares": "Modifier la marge de profit et les frais d’Intair",
  "Edit Intair Fares":"Modifier les tarifs Intair",
  "Existing Fare without Additional Markup":
    "Tarif existant sans la marge de profit additionnelle",
  "PNR cannot be priced because it is Cancelled.":
    "Le PNR ne peut pas être évalué car il est annulé.",
  "No PQ present in the PNR": "Pas de PQ présent dans le PNR",
  "Send email to": "Envoyer un e-mail à",
  "Travel agent": "Agent de voyage",
  "Enter Record Locator": "Entrez le numéro de dossier",
  "Email to travel agent": "E-mail à l'agent de voyage",
  "Cancellation of a ticketed PNR":
    "L'annulation d'un PNR à ticket n'est actuellement pas autorisée. Veuillez nous contacter.",
  "Enter username or userId": "Entrez le nom d'utilisateur ou l'utilisateur",
  UNKNOWN: "INCONNU",
  Cnx: " Corresp.",
  "Enter Email": "Saisissez l'adresse courriel",
  "Please Enter Client Email":
    "Saisissez l'adresse courriel du client",
  "Enter Client Name": "Saisissez le nom du client",
  "Enter Client Email": "Saisissez l’adresse courriel du client",
  English: "Anglais",
  French: "Français",

  "No Private fare returned for": "Pas tarif privé pour",
  "Intair does not have the ticketing authority to issue tickets with this carrier.":"Intair n'a pas la permission d'émettre des billets avec ce transporteur.",
  "No fare returned for": "Pas de tarif",
  "No Public fare returned for": "Pas de tarif public pour",

  "A technical issue occurred, please contact us":
    "Un problème technique s'est produit, veuillez nous contacter.",
  Reprice: "Recalculer",
  "Passenger Name": "Nom du passager",
  "Passenger Type": "Type de passager",
  "Our Commission": "Notre commission",
  "Status in PNR is Confirmed": "Le statut dans PNR est confirmé",
  "Status in PNR shows Unconfirmed.": "Le statut dans PNR montre non confirmé.",
  "Status in file currently shows unconfirmed;":
    "Le statut dans le fichier affiche actuellement non confirmé; Veuillez vérifier à nouveau plus tard. Après 24 heures, l'état indiqué est définitif.",
  "Status in PNR is failed": "Le statut dans PNR est échoué",
  Pending: "En attente",
  "MM/DD/YYYY": "MM/JJ/AAAA",
  "MM/YYYY": "MM/AAAA",
  "mm/dd/yy":"mm/jj/aa",
  "Do you want to apply these markups.": "Voulez-vous appliquer cette marge de profit?",
  "Please Fill All The Mandatory Fields.": "Veuillez remplir tous les champs obligatoires.",
  "Non Ticketed PNR Cancelled": "PNR annulé billet non émis",
  "Confirmed - Payment Required": "Confirmée – Paiement dû",
  "Payment Required":"Paiement dû",
  "Paid - Ticketed":"Payé - Émis",
  "Paid - Not Ticketed":"Paye – Pas Émis",
  "Ticketed PNR Cancelled": "PNR émis et annulé",
  "Paid Non Ticketed PNR Cancelled": "PNR non billet payé annulé",
  "Additional Filters": "Filtres supplémentaires",
  "Fare options cannot be displayed since no fare options are available for this fare.": "Les options tarifaires ne peuvent être affichées puisqu'aucune option tarifaire n'est disponible pour ce tarif.",
  "Have cancelled": "Avoir annulé",
  "Unable to confirm": "Impossible de confirmer",
  "Flight Cancelled by Airline.": "Vol annulé par la compagnie aérienne.",
  "No action taken.": "Pas d'action prise.",
  Private: "Privé",
  Published:"Publié",
  Negotiated:"Négocié",
  "Published Non-Commissionable":"Publié sans-commission",
  "Published Commissionable":"Publié avec commission",
  "Land Net":"Portion terrestre NET",
  "Cruise Net":"Croisière NET",
  "*Required": "*Requis",
  "Please specify full name":"Veuillez spécifier le nom complet",
  "Cheque Number should not be blank":	"Le numéro de vérification ne doit pas être vide",
   "Add/Edit special requests":"Ajouter/Modifier des demandes spéciales",
   "Add/Edit frequent traveler, Redress, Passport Info":"Ajouter/modifier un voyageur fréquent, une réparation, des informations sur le passeport",
   "Want to add/edit special requests" : "Vous souhaitez ajouter/modifier des demandes spéciales, des voyageurs fréquents, des réparations ou des informations sur votre passeport?",
  "EMD Payment In Progress":"L'émission des documents électroniques pour les sièges prépayés est en cours.",
  "EMD issuance not attempted": "L'émission de l'EMD n'a pas été tentée car l'émission du ticket a échoué",
  "Fetch Exclusive Blockspace Flight Deals": "Veuillez patienter pendant que nous récupérons les offres de vols exclusives.",
   // Paragraphs //
 
  "Full payment":
    "Le paiement complet de la réservation est nécessaire pour sécuriser les sièges prépayés",
  "As maximum": "Aussi maximum",
  "characters are allowed in":
    "Les caractères sont autorisés dans le prénom, veuillez changer le prénom en",
  "Maximum number of characters":
    "Le nombre maximum de caractères autorisés pour les prénoms et les prénoms a été atteint; Veuillez supprimer les prénoms",
  "characters are allowed":
    "Les caractères sont autorisés dans les prénoms, veuillez changer le deuxième prénom en",
  "characters are allowed in middle names":
    "Les caractères sont autorisés dans les prénoms, veuillez changer en mettant uniquement les premières initiales",
  "As maximum 25":
    "Comme le maximum 25 caractères sont autorisés dans le nom de famille, veuillez changer le nom de famille en",
  "A child must be 2 to 11 years old.":
    "Un enfant doit avoir 2 à 11 ans. Veuillez relancer votre recherche en considérant ce passager en tant qu'adulte.",
  "A child must be 2 to 11 years old. This passenger":
    "Un enfant doit avoir 2 à 11 ans. Ce passager est considéré comme un nourrisson qui aura 2 ans en cours de route. Pour cette raison, le transporteur a une politique spéciale. Veuillez nous contacter pour faire la réservation.",
  "A child must be 2 to  11 years old. Please relaunch":
    "Un enfant doit avoir 2 à 11 ans. Veuillez relancer votre recherche en considérant ce passager comme un bébé.",
  "This passenger is considered as an infant":
    "Ce passager est considéré comme un nourrisson qui aura 2 ans en cours de route. Pour cette raison, le transporteur a une politique spéciale. Veuillez nous contacter pour faire la réservation.",
  "A infant must be less than 2 years old.":
    "L'âge du bébé doit être plus bas que 2 ans. Veuillez relancer votre recherche en considérant ce passager comme un enfant.",
  "You are about to book an unaccompanied minor (UMNR).":
    "Vous êtes sur le point de réserver une mineure non accompagnée (UMNR). Vous devez contacter le transporteur pour vérifier si votre passager être accepté à bord et s'il y a des procédures obligatoires Cela doit être suivi.",
  "An adult must be at least 12 years old.":
    "Un adulte doit avoir au moins 12 ans. Veuillez relancer votre recherche compte tenu de ce passager comme un enfant.",
    "A child must be 1 to 17 years old.":
    "Un enfant doit avoir 1 à 17 ans. Veuillez relancer votre recherche en considérant ce passager en tant qu'adulte.",
  "A child must be 1 to 17 years old. This passenger":
    "Un enfant doit avoir 1 à 17 ans. Ce passager est considéré comme un nourrisson qui aura 2 ans en cours de route. Pour cette raison, le transporteur a une politique spéciale. Veuillez nous contacter pour faire la réservation.",
  "A child must be 1 to  17 years old. Please relaunch":
    "Un enfant doit avoir 1 à 17 ans. Veuillez relancer votre recherche en considérant ce passager comme un bébé.",
  "This passenger is considered as an infants":
    "Ce passager est considéré comme un nourrisson qui aura 1 ans en cours de route. Pour cette raison, le transporteur a une politique spéciale. Veuillez nous contacter pour faire la réservation.",
  "A infant must be less than 1 year old.":
    "L'âge du bébé doit être plus bas que 1 ans. Veuillez relancer votre recherche en considérant ce passager comme un enfant.",
  "An adult must be at least 18 years old.":
    "Un adulte doit avoir au moins 18 ans. Veuillez relancer votre recherche compte tenu de ce passager comme un enfant.",
  "Please scroll up to review":
    "Veuillez faire défiler vers le haut pour examiner les messages d'erreur et mettre à jour les données en conséquence.",
  "Please select the":
    "Veuillez sélectionner la case à cocher ci-dessus pour continuer.",
  "Unable to display the details.":
    " Impossible d'afficher les détails. Aucune donnée de segment de vol et PQ n'est disponible dans le PNR",
  "Agency Code & Name": "Code et nom d'agence",
  "Travel Agent Name": "Nom de l’agent de voyage",
  "Personal Username": "Nom d'utilisateur personnel",
  "Emergency Contact Phone Number": "Numéro de téléphone du contact d’urgence",
  "id :": "identifiant :",
  "PNR is Cancelled. Cannot Reprice.":
    "PNR est annulé. Ne peut pas se reproduire.",
  "Please note that the fare.":
    "Veuillez noter que le tarif ci-dessous sera recalculé au moment de la réservation.",
  "This is a last minute travel departing.":
    "Ceci est un départ dans moins de 4 heures. Veuillez nous contacter pour la réservation et l'émission des billets.",
  "Segments data is not available":
    "Les données de segments ne sont pas disponibles",
  "Your Booking is": "Votre réservation est",
  "Your file will be on hold until": "Votre dossier sera en option jusqu'au",
  "Eastern Time.Only a payment made":
    "heure de l'Est. Seul un paiement effectué le jour même de la réservation et avant l'heure limite d'émission du billet garantit ce tarif. Au-delà de cela, Voyage TravelBrands ne sera pas responsable des changements de tarifs, de taxes ou de suppléments qui peuvent survenir et qui sont hors de notre contrôle.",
  "Credit cards will be charged":
    "Les cartes de crédit ne seront débitées que si des frais de Voyages TravelBrands et/ou de votre agence s'appliquent.",
  "Email to travel passanger": "Email pour voyager Passanger",
  "Repricing a PNR that is paid or ticketed is not allowed.":
    "La réparation d'un PNR payé ou ticket n'est pas autorisée.",
  "Unable to fetch existing fare details from the PNR.":
    "Impossible de récupérer les détails des tarifs existants du PNR.",
  "Payable amount equals to selling amount + Merchant Fees when applicable":
    "Le montant à payer équivaut au montant de vente + frais de marchand si applicable",
  "Payable amount equals to Published Base Fare + Taxes and Fees + Markup Applied and Merchant Fees when applicable":
    "Le montant payable est égal au tarif de base publié + les taxes et frais + la surcommission appliquée et les frais de marchand, le cas échéant.",
  "Payable amount equals to Net Base Fare + Taxes and Fees":
    "Le montant payable est égal aux tarifs de base nets + taxes et frais",
  "The option and/or ticketing deadline for the existing fare on this PNR has expired.":
    "La date limite d'option et / ou de billetterie pour le tarif existant sur ce PNR a expiré.",
  "Unable to price the PNR, please verify PNR record number.": "Impossible de recalculer ce PNR. Veuillez vérifier le numéro de dossier.",
  "Remark with your Intair code 5**PNR Owner-CXXXX**  is required in the PNR, please add and submit your PNR again.": "Remarque avec votre code Intair 5 ** Le propriétaire PNR-CXXXX ** est requis dans le PNR, veuillez ajouter et soumettre à nouveau votre PNR.",
  "Your itinerary could not get priced because one of the flights came back": "Votre itinéraire n'a pas pu se faire un prix parce que l'un des vols est revenu",
  "please try again later or book alternative flights.": "Veuillez réessayer plus tard ou réserver des vols alternatifs.",
  "Fare no longer saved in the PNR": "Le prix n'est plus sauvé dans le PNR.",

  // Error Messages & Responses from Api
  "Quote is saved": "Votre itinéraire est sauvegardé",
  "Quote is deleted successfully": "Votre itinéraire sauvegardé est supprimé avec succès.",
  "Passport information is mandatory to apply payment and issue tickets.": "Informations du passeport obligatoires au moment de la réservation",
  "This pnr does not belong to" : "Ce pnr n'appartient pas à ",
  "agent" : " agent ",
  ". Please verify travel agency and agent name field." : ". Veuillez vérifier le champ du nom de l'agence de voyage et de l'agent.",
  "AIRLINE REFERENCE" : "RÉFÉRENCE DE LA COMPAGNIE AÉRIENNE ",
  "Regular Refund payment" : "Remboursement régulier",
  "Value must be" : "La valeur doit être ",
  "or lower" : " ou moindre.",
  "Last 7 Days" : "Les 7 derniers jours",
  "Last 4 Weeks" : "4 dernières semaines",
  "Last 6 Months" : "6 derniers mois",
  "Last 12 Months" : "12 derniers mois",
  "Selected Itinenary Time Expired":"La fenêtre de réservation de l'itinéraire sélectionné a expiré. Veuillez effectuer une nouvelle recherche.",
  "Fare Already Booked":"Impossible de réserver  ce tarif car vous avez déjà tenté de réserver une fois. Veuillez effectuer une nouvelle recherche.",
  "Owner-CXXXX** is required in the PNR":"La remarque PNR OWNER est incorrecte. Veuillez corrigez afin qu’elle corresponde exactement au format suivant 5**PNR OWNER-CXXXX**",

  //Duffle
  "Number of available seats and free bags":"Number of available seats and free bags, viewing seat map and making seat selection, fare rules and penalties on search results page, booking and confirmation pages.",
  "Number of free bags and cost":"Number of free bags and other available amenities are shown on branded fares page.",
  "Passenger gender, date of birth and contact details":"Passenger gender, date of birth and contact details must be accurate at time of booking as they cannot be modified afterwards.",
  "The file number is the airline record locator number.":"The file number is the airline record locator number.",
  "Special request, frequent traveler and passport information cannot yet be done through GIGI.":"Special request, frequent traveler and passport information cannot yet be done through GIGI.",
  "Booking on hold is not yet allowed":"Booking on hold is not yet allowed",
  "Payment - TBA":"Payment - TBA",
  "GIGI booking confirmation email will be automatically sent":"GIGI booking confirmation and electronic ticket emails are automatically sent",
  "GIGI electronic ticket email  – TBA":"GIGI electronic ticket email  – TBA",
  "Invoicing is done manually and may take up to 48 hours to be sent.":"Invoicing is done manually and may take up to 48 hours to be sent.",
  "Contact us to cancel your booking.":"Contact Intair to cancel your booking",
  "By selecting this fare, the booking will be done":"By selecting this fare, the booking will be done directly with Air Canada. You will be able to access this booking by going to ",
  "www.aircanada.com":"www.aircanada.com ",
  "and":"and",
  "view detailed baggage allowances.":"a. view detailed baggage allowances.",
  "add and change some travel options.":"b. add and change some travel options.",
  "view seat map, make and pay seat selection":"c. view seat map, make and pay seat selection",
  "link your passenger’s frequent flyer number.":"d. link your passenger’s frequent flyer number.",
  "Print and email the itinerary with seat":"e. Print and email the itinerary with seat and ticket numbers, aircraft type, detailed bag information etc.",
  "Instant purchase mandatory; only one credit card number is allowed":"Only same payment method for all the travelers is allowed and pax credit card billing address is required",
  "Only same payment allowed": "Le même mode de paiement est requis pour tous les passagers.",
  "Only same payment method for all the travelers is allowed and pax credit card billing address is required":"Only same payment method for all the travelers is allowed and pax credit card billing address is required",
  "Instant Purchase" : "Paiement immédiat",
  "The carrier could not confirm this fare due to lack of booking class availability." : "La compagnie aérienne n’a pu confirmer ce prix en raison du manque de disponibilité des classes de service.  Veuillez effectuer une nouvelle recherche.",
  "By Card" : "Par carte ",
  "Card expiry date" : "Date d'expiration de la carte ",
  "Passenger Name couldn't":"Nom du passager ne correspond plus aux informations “Secure Flights”. Veuillez reconfirmer et enregistrer à nouveau la date de naissance et le genre.",
  "Frequent Traveler, Redress" : "Redress",
  "The Price Summary details and Payment":"Les détails du Sommaire du prix et le Paiement en ligne ne sont plus disponibles; veuillez nous contacter",
  "We were unable to complete booking":"Nous n'avons pu completer la reservation. Une ou plusieurs classes de service ne sont plus disponibles. Veuillez effectuer une nouvelle recherche.",
  "Flight number(s)" : "Numéro(s) de vol",
  "Wrong input, please follow AC 843, LH 1912" : "Mauvaise saisie, veuillez vous référer aux éléments suivants AC 843, LH 1912",
  "Wrong input because of ',' after airline code" : "Mauvaise saisie à cause de ',' après le code de la compagnie aérienne ",
  "Wrong input because" : "Mauvaise saisie car",
  "is missing the preceding 2 letter airline code." : " manque le code de la compagnie aérienne à 2 lettres qui précède.",
  "Wrong input, please follow 843, 1912" : "Mauvaise saisie, veuillez suivre 843, 1912",
  "Add multiple flight numbers separated by a comma. E.g AC 843, LH 1912" : "Ajoutez plusieurs numéros de vol séparés par une virgule. Par exemple AC 843, LH 1912  or  843, 1912",
  "Redress, Passport Info" : "Redress, informations sur le passeport",
  "Invalid Phone Number":"La réservation a échoué en raison d'un numéro de téléphone invalide. Veuillez vous assurer d'avoir saisi un numéro de téléphone valide et réessayez.",
  "fare will be recalculated with your markup and price summary updated accordingly. If you agree with new fare, you need to click on Save Markup button in order to save the fare in your file.":"Le tarif sera recalculé avec votre majoration et le récapitulatif des prix mis à jour en conséquence. Si vous êtes d'accord avec le nouveau tarif, vous devez cliquer sur le bouton Enregistrer la marge de profit afin d'enregistrer le tarif dans votre fichier.",
  "Applicable commission on refundable tickets will be recalled":"La commission applicable sur les billets remboursables sera réclamée et devra être payée en totalité pour compléter le remboursement et la facturation.",
  "Apply": "Appliquer",
  "Add/Modify Agency Markup":" Ajouter/Modifier la marge de profit de l'agence",
  "Save Changes":"Sauvegarder les modifications",
  "Discard Changes":"Annuler les modifications",
  "Do you want to save the changes made to":"Souhaitez-vous enregistrer les modifications apportées à",
  "Go Back":"Retourner",
  "You have unsaved changes in the":"Vous avez des modifications non enregistrées dans le ",
  "Refunded":"Remboursé",
  "Please Note that the taxes were recalculated":
  "Veuillez noter que les taxes ont été recalculées",
  "Please wait while we are updating the markup.": "Veuillez patienter pendant que nous mettons à jour la marge de profit...",
  "Success": "Succès!",
  "Markup Successfully Updated": "La marge de profit a été mis à jour avec succès!",
  "Please wait while we fetch the latest fare." : "Veuillez patienter pendant que nous récupérons les détails du tarif.",
  "GIGI attempted to add your new additional markup":"GIGI a tenté d'ajouter votre nouvelle marge de profit mais le transporteur a augmenté le tarif présentement confirmé. Contactez-nous pour que votre marge de profit soit ajoutée manuellement au tarif actuel afin d’éviter l’augmentation du transporteur.",
  "Please enter valid passport number" : "Veuillez entrer un numéro de passeport valide",
  "Identical names are not permitted to be booked in the same reservation." : "Les noms identiques ne sont pas permis sur la même réservation.",
  "PNR was booked in a PCC that doesn’t allow it to be priced using the Calculator":"Le transporteur exige que l'itinéraire soit réservé directement via Intair. Veuillez utiliser Gigi pour effectuer votre recherche et réservation.",
  "A ticketed PNR cannot be repriced using the Calculator":"Un PNR émis ne peut pas être retarifé à l'aide du calculateur.",
  "A paid PNR cannot be repriced using the Calculator":"Un PNR payé ne peut pas être retarifié à l'aide du calculateur.",
  "Prepaid Seat document number(s) will be added in the seat selection details when issuance is completed." : "Le numéro de document des sièges prépayés sera ajouté dans les détails de la sélection des sièges, une fois l'émission complétée.",
  "The fare options selected do not apply to the cabin type":"Les options tarifaires sélectionnées ne s'appliquent pas au type de cabine actuellement réservée. Vérifiez et modifiez les classes de réservation et la cabine, puis réessayez.",
  "There is currently no price with this validating carrier for this itinerary.":"Il n'y a actuellement aucun prix avec ce transporteur émetteur pour cet itinéraire.",
  "This combination of different fares options per passenger cannot be priced":"Cette combinaison de différentes options tarifaires par passager ne peut pas être calculée sur le même PNR car les classes de réservation diffèrent selon le passager.",
  "Invalid Card!" : "Carte invalide!",
  "card isn’t allowed" : " la carte n'est pas autorisée",
  "Segment status is other than HK" : "L'itinéraire de ce fichier comporte des segments inexistants ou non confirmés.",
  "Intair does not have the ticketing authority to issue tickets with this carrier.":"Intair n'a pas la permission d'émettre des billets avec ce transporteur.",
  "There is no price applicable to the booking classes":"Aucun prix n’est applicable aux classes de réservation et cabines actuellement réservées. Vérifiez et modifiez les classes de réservation et cabines, puis réessayez.",
  "Thank you. Your payment has been saved in your file and tickets will be issued. Click on the file number to view the ticketing status." : "Merci. Votre paiement a été enregistré à votre dossier et les billets seront émis. Veuillez cliquer sur le numéro du dossier pour voir le statut de l'émission.",
  "Enter  the file number to price the itinerarry at the lowest available fare.":"Entrez le numéro de dossier pour calculer l’itinéraire au tarif le moins cher disponible.",
  "Want to reprice with specific fare options?":"Vous désirez recalculer avec des options tarifaires spécifiques? Sélectionnez dans le menu déroulant ou saisissez le code d’option tarifaire du transporteur.",
  "Want to see fares from an alternate validating carrier?":"Vous désirez voir les tarifs avec un autre transporteur émetteur?",
  "Fare options selection will become available once the itinerary is repriced":"La sélection des options tarifaires sera disponible une fois l’itinéraire recalculé au au tarif le moins cher disponible avec le transporteur émetteur sélectionnné.",
  "You have selected only child passenger type.":"Vous avez sélectionné uniquement le type de passager enfant.  Un passager enfant doit être accompagné d'un adulte. Vous êtes tenu de vous assurer que tel est le cas et référencer les deux dossiers.",
  "Exclusive Flight Deals":"Offres de vols à tarifs exclusifs",
  "Exclusive Flight Deals Ref" : "No Ref d’Offres de vols à tarifs exclusif",
  "Select a filter to narrow down the available options." : "Sélectionnez un filtre pour affiner les options disponibles.",
  "Departure Date" : "Date de départ",
  "First class" : "Première classe",
  "View Fare Rules" : "Consulter Règlements du tarif",
  "Premium Econonmy" : "Économie supérieure",
  "Passenger" : "Passager",
  "Number of Passengers" : "Nombre de Passagers",
  "Agency Name" :"Nom d'agence",
  "Confirmation Number" : "Numéro de confirmation",
  "Number of Passengers" : "Nombre de Passagers",
  "Price PNR":"Calculer l’itinéraire",
  "Select passenger type against each passenger to continue pricing the PNR":"Sélectionnez le type de passager pour chaque passager pour continuer à calculer l’itinéraire",
  "Lowest available fares":"Tarifs les moins chers disponibles",
  "Enter the file number to price the itinerary at the lowest available fare.":"Entrez le numéro de dossier pour calculer l’itinéraire au tarif le moins cher disponible.",
  "Clear All":"Effacer",
  "Select an option":"Selectionnez une option",
  "GIGI attempted to save this fare and received a warning that the child":"GIGI a tenté de sauvegarder ce tarif et a reçu un avertissement indiquant que l'enfant doit être réservé dans un dossier séparé en raison des différentes classes de réservation requises. Merci de nous contacter pour diviser votre dossier.",
  "Other":"Autres",
  "Partial EMD issued. Contact customer service for status of remaining EMDs.":"EMD partiel émis. Contactez le service client pour connaître le statut des EMD restants.",
  "EMD issuance failed. Our ticketing agents will manually issue EMDs.":"Émission d'EMD échouée. Nos agents de billetterie émettront manuellement les EMD.",
  "E-TicketNumber": "Numéro de billet électronique",
  "Payment Successful!" : "Paiement réussi!",
  "File Number" : "Numéro du dossier",
  "Day":"Jour",
  "Month":"Mois",
  "Year":"An",
  "Years":"Ans",
  "It can’t be a future date.":"Date de naissance du bébé doit être antérieure à la date de réservation.",
  "Please Select":"Veuillez sélectionner",
  "Passenger: Electronic ticket":"Passager: Billet électronique",
  "Passenger: Itinerary only":"Passager: Itinéraire seulement",
  "Agency: Electronic ticket":"Agence: Billet électronique",
  "Agency: Net Fare and Itinerary":"Agence: Itinéraire et prix",
  "Add/Modify Agency Markup and Modify Intair markup and fees":" Ajouter/Modifier la marge de profit de l'agence et Modifier la marge de profit et les frais d’Intair",
  "Save Markup" : "Enregistrer Marge de profit",
  "We are sorry, payment cannot be applied to this booking due to a technical issue. Please contact us for assistance.":"Nous sommes désolés, le paiement ne peut pas être appliqué à cette réservation en raison d'un problème technique. Veuillez nous contacter pour obtenir de l'aide.",
  "We are unable to combine the fare options selected for this multi-city itinerary. Please contact us." : "Nous ne pouvons pas combiner les options tarifaires sélectionnées pour cet itinéraire multi-destinations. Veuillez nous contacter.",
  "Total for":"Total pour",
  "Total Base Fare": "Total tarif de base",
  "Total Airline taxes & fees": "Total taxes et frais du transporteur",
  "Total Service fee":"Total frais de service",
  "Total Ticketing fee":"Total frais d'émission",
  "Total Markup Applied":"Total marge de profit appliquée",
  "Total all passengers":"Total tous les passagers",
  "Generate Invoice":"Générer une facture",
  "Successfully generated the invoice on Travcom.":"La facture a été générée avec succès sur Travcom.",
  "Failed to generate the invoice on Travcom, please try again.":"Échec de la génération de la facture sur Travcom, veuillez réessayer.",
  "Your file shows that tickets have been issued by the carrier": "Votre fichier indique que les billets ont été émis par le transporteur et que la demande de remboursement est immédiatement envoyée au transporteur. En cliquant sur oui, vous confirmez avoir lu les informations ci-dessous.",
  "Loyalty points awarded will be adjusted accordingly.": "Les points de fidélité attribués seront ajustés en conséquence.",
  "If applicable, Travelbrands service fees + taxes will be charged.": "Si applicable, des frais de service de Travelbrands ainsi que les taxes seront facturés.",
  "Your commission in full needs to be recalled. Send an email to aircommissionrecall@travelbrands.com": "Votre commission intégrale doit être rappelée. Envoyez un e-mail à aircommissionrecall@travelbrands.com ",
  "If you wish for Intair to charge your own fees and include your commission recall.": " si vous souhaitez qu'Intair facture vos propres frais et inclue le rappel de votre commission.",
  "All transactions are processed in the original form of payment.": "Toutes les transactions sont traitées selon le mode de paiement d'origine.",
  "Thank you. The itinerary for file XXXXXX has been canceled and your refund request sent to the carrier.":"Merci. L'itinéraire pour le dossier XXXXXX a été annulé et votre demande de remboursement a été envoyée au transporteur.",
  "Please allow a minimum of three weeks for the refund to be processed and credited to the original form of payment.":"Veuillez prévoir un délai minimum de trois semaines pour le traitement du remboursement et son crédit sur le mode de paiement d'origine.",
  "You will receive an updated invoice once the carrier has confirmed that the refund has been processed.":"Vous recevrez une facture mise à jour une fois que le transporteur aura confirmé que le remboursement a été traité.",
  "Base fare and total taxes per passenger adult, child and infant is not available.  Only the total for the whole party traveling is showing." : "Le tarif de base et le total des taxes par passager adulte, enfant et bébé n’est pas disponible. Seul le total pour tous les passengers est affiché.",
  "Number of open seats to book is not obtainable." : "Le nombre de sièges disponibles n’est pas disponible.",
  "Passenger gender, date of birth and contact details must be accurate at time of booking as they cannot be modified afterwards through Gigi." : "Le genre, date de naissance et détails de contact du passager doivent être exacts au moment de la réservation car ils ne peuvent être modifiés par GIGI.",
  "Only the same payment method for all the travelers is allowed and passenger credit card billing address is required." : "Une seule forme de paiement pour tous les passagers est acceptée et l’adresse du détenteur de la carte de crédit est requise.",
  "Gigi file number is the airline record locator number." : "Le numéro de dossier Gigi est celui du transporteur.",
  "GIGI booking confirmation and electronic ticket emails are automatically sent." : "Les courriels de confirmation des réservations et de billets électroniques de GIGI sont automatiquement envoyés.",
  "Invoicing is done manually and may take up to 48 hours to be sent." : "La facture est faite manuellement et peut prendre jusqu'à 48 heures avant d'être envoyée.",
  "Contact Intair to cancel your booking." : "Vous devez contacter Intair pour annuler votre réservation.",
  "By selecting this fare, the booking will be done directly with the carrier. You will be able to access this booking by going on the carrier’s website and use the options that are provided such as:" : "En sélectionnant ce tarif, la réservation est faite directement avec le transporteur.  Vous serez capable d’accéder à la réservation via le site internet du transporteur et utiliser les fonctions disponibles telles que:",
  "View baggage allowances and information about the fare option  booked." : "● Voir les franchises de bagages et informations sur l’option tarifaire réservée.",
  "Add and change some travel options." : "● Ajouter ou modifier certaines options de voyage.",
  "Do some special requests, add frequent traveler and passport information." : "● Faire certaines demandes spéciales, ajouter un numéro de voyageur fréquent ou des informations passeport.",
  "View seat map, make and pay seat selection." : "● Voir le plan de cabine , faire et payer une sélection de sièges.",
  "Print and email the itinerary or electronic ticket." : "● Imprimer et envoyer un courriel de confirmation d’itinéraire ou billet électronique." ,
  "Web fare booking is currently not permitted for same day departure.":"La réservation de tarifs Web pour un départ le même jour n'est actuellement pas autorisée.",
  "We have received your cancellation request.":"Nous avons reçu votre demande d'annulation. Un courriel a été envoyé à notre équipe d’urgence des services aériens qui annulera ce dossier pour vous. Lorsque le dossier sera annulé, le statut de votre dossier affichera « annulé » dans Gigi.",
  "Update Booking Status":"Mettre à jour le Statut de la Réservation",
  "This button will update the booking status to show “Ticketed PNR Cancelled.”":"Ce bouton mettra à jour le statut de la réservation pour afficher « PNR émis et annulé ». ",
  "You must first cancel this booking through aircanada.com\travel agent portal.":"Vous devez d’abord annuler cette réservation via le portail aircanada.com\travelagent. ",
  "You then come back to GiGi to click on this button.":"Revenez ensuite dans GiGi et pour cliquer sur ce bouton.",
  "Is this booking cancelled with Air Canada?":"Cette réservation est-elle annulée avec Air Canada?",
  "The booking class along with all the fare option details and rules are shown on " : "La classe de service  ainsi que les règles et détails de l’option tarifaire sont affichées sur ",
  "Thank you for submitting your payment." : "Merci d'avoir soumis votre paiement.",
  "We are redirecting your payment information to the carrier." : "Nous redirigeons vos informations de paiement vers le transporteur. L'autorisation de chèque ou de carte de crédit du montant total du tarif publié + taxes est traitée par le transporteur. Cliquez sur le numéro de dossier pour afficher le statut de la réservation.",
  "If payment with the carrier is successful, the booking status will" : `Si le paiement auprès du transporteur réussit, le statut de la réservation apparaîtra comme "billet payé".`,
  "If payment with the carrier is unsuccessful, the booking status will" : `Si le paiement auprès du transporteur échoue, le statut de la réservation affichera "contactez-nous".`,
  "Are you sure you want to change the status of this booking to" : "Êtes-vous sûr de vouloir changer le statut de cette réservation en ",
  "Paid- Ticketed" : "Payé- Émis",
  "Confirmed – payment required" : "Confirmé – paiement requis",
  "Contact Us" : "Contactez-nous",
  "This button updates the booking status when a transaction was done through AC Call Centre or AC travel agent website and GiGi did not synchronize. You must first process the transaction with AC and then come back to GiGi to verify booking status and update it if need be." : "Ce bouton met à jour le statut de la réservation lorsqu'une transaction a été effectuée via le centre d'appels AC ou le site Web de l'agent de voyages AC et que GiGi n'a pas été synchronisé. Vous devez d'abord traiter la transaction avec AC puis revenir sur GiGi pour vérifier le statut de la réservation et la mettre à jour si nécessaire.",
  "Agent or agency is not allowed to access any bookings.":"L'agent ou l'agence n'est pas autorisé à accéder à aucune réservation.",
  "Agent or agency is not allowed to make bookings.":"L'agent ou l'agence n'est pas autorisé à effectuer des réservations.",
  "Please note that the taxes were recalculated. Do you want to proceed with payment?": "Veuillez noter que les taxes ont été recalculées. Souhaitez-vous procéder au paiement ?",
  "Your session has expired due to inactivity or a token expiration. Please log in again to continue." : "Votre session a expiré en raison d'inactivité ou de l'expiration d'un jeton. Veuillez vous reconnecter pour continuer.",
  "Restriction may apply. Please contact the carrier": "Des restrictions peuvent s’appliquer. Veuillez contacter le transporteur.",
  "Please enter a valid DOB in the format DDMMMYY, e.g., 12AUG90": "Veuillez saisir une date de naissance valide au format DDMMMYY, par exemple 12AUG90",
  "Invalid dates: birthdate is after departure date": "Dates invalides : la date de naissance est postérieure à la date de départ",
  "Please wait while GiGi is retrieving your booking data.":"Veuillez patienter pendant que GiGi récupère vos données de réservation.",
  "GiGi is verifying the taxes and updating the values in your price summmary.":"GiGi vérifie les taxes afin de mettre à jour les montants dans votre sommaire du prix."
};
